<template>
  <div ref="marginsWarning" id="margins-warning" v-show="visible && !$parent.$parent.locked" @mousedown="dragMouseDown">
    <div class="icon icon-close" v-on:click="visible = false"></div>
    <span class="margins-warning-header">{{ Lang.get('modals.margins.header') }}</span>
    <span class="margins-warning-text">{{ Lang.get('modals.margins.text') }}</span>
    <img class="margins-warning-img-1" src="/images/margins/margins-warning-1.png" />
    <img class="margins-warning-img-2" src="/images/margins/margins-warning-2.png" />
  </div>
</template>

<script>
import { bus } from '../utils/bus'
import Lang from '../utils/lang'

export default {
  name: 'MarginsWarning',
  data: function () {
    return {
      Lang: Lang,
      visible: false,
      positions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0,
      },
      boundaries: {
        minX: 0,
        maxX: 0,
        minY: 0,
        maxY: 0,
      },
    }
  },
  methods: {
    setInitialPosition: function (systemMessageVisible) {
      let workspaceRef = this.$parent.$parent.$refs.workspace
      let spineActive = this.$parent.$parent.spineActive

      if (this.$refs.marginsWarning) {
        this.$refs.marginsWarning.style.top = workspaceRef.clientHeight - 235 - 40 + 'px'
        this.$refs.marginsWarning.style.left = workspaceRef.clientWidth / 2 - 246 + 'px'
      }

      this.visible = !spineActive
    },
    dragMouseDown: function (event) {
      event.preventDefault()
      this.positions.clientX = event.clientX
      this.positions.clientY = event.clientY
      document.onmousemove = this.elementDrag
      document.onmouseup = this.closeDragElement
    },
    elementDrag: function (event) {
      event.preventDefault()

      if (this.checkBoundaryExceeded('left')) {
        this.$refs.marginsWarning.style.left = this.boundaries.minX + 1 + 'px'
      } else if (this.checkBoundaryExceeded('right')) {
        this.$refs.marginsWarning.style.left = this.boundaries.maxX - 1 + 'px'
      } else if (this.checkBoundaryExceeded('top')) {
        this.$refs.marginsWarning.style.top = this.boundaries.minY + 1 + 'px'
      } else if (this.checkBoundaryExceeded('bottom')) {
        this.$refs.marginsWarning.style.top = this.boundaries.maxY - 1 + 'px'
      } else {
        this.positions.movementX = this.positions.clientX - event.clientX
        this.positions.movementY = this.positions.clientY - event.clientY
        this.positions.clientX = event.clientX
        this.positions.clientY = event.clientY
        this.$refs.marginsWarning.style.top = this.$refs.marginsWarning.offsetTop - this.positions.movementY + 'px'
        this.$refs.marginsWarning.style.left = this.$refs.marginsWarning.offsetLeft - this.positions.movementX + 'px'
      }
    },
    checkBoundaryExceeded: function (boundary) {
      let exceeded = false
      switch (boundary) {
        case 'left':
          exceeded = this.$refs.marginsWarning.offsetLeft < this.boundaries.minX
          break
        case 'right':
          exceeded = this.$refs.marginsWarning.offsetLeft >= this.boundaries.maxX
          break
        case 'top':
          exceeded = this.$refs.marginsWarning.offsetTop < this.boundaries.minY
          break
        case 'bottom':
          exceeded = this.$refs.marginsWarning.offsetTop >= this.boundaries.maxY
          break
      }
      return exceeded
    },
    closeDragElement() {
      document.onmouseup = null
      document.onmousemove = null
    },
  },
  mounted() {
    this.boundaries.minX = -350
    this.boundaries.maxX = this.$parent.$parent.$refs.workspace.clientWidth - 492
    this.boundaries.minY = 0
    this.boundaries.maxY = this.$parent.$parent.$refs.workspace.clientHeight - 235
    bus.$on('zoomControlsVisible', (systemMessageVisible) => {
      this.setInitialPosition(systemMessageVisible)
    })
  },
}
</script>
