var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{ 'sub-header-bar-spine': _vm.$parent.spineActive },attrs:{"id":"sub-header-bar"}},[(_vm.$parent.spineActive)?_c('div',{attrs:{"id":"sub-header-bar-left"}},[_c('a',{staticClass:"btn btn-spine-close",on:{"click":function($event){return _vm.$parent.$refs.fabric.switchSpreadPage('left')}}},[_c('div',{staticClass:"icon icon-chevron-left"}),_vm._v(" "+_vm._s(_vm.Lang.get('subheader.buttons.exit-spine-editor'))+" ")])]):_vm._e(),(!_vm.$parent.spineActive)?_c('div',{attrs:{"id":"sub-header-bar-left"}},_vm._l((_vm.$parent.subheaderBarItems),function(subheaderBarItem){return _c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.bottom",value:({
        title:
          subheaderBarItem.name === 'crop' && _vm.$parent.getSubheaderBarItemClass(subheaderBarItem)
            ? _vm.Lang.get('canvas.warnings.image-outside-canvas')
            : '',
      }),expression:"{\n        title:\n          subheaderBarItem.name === 'crop' && $parent.getSubheaderBarItemClass(subheaderBarItem)\n            ? Lang.get('canvas.warnings.image-outside-canvas')\n            : '',\n      }",modifiers:{"bottom":true}}],key:subheaderBarItem.name,staticClass:"d-inline-block",attrs:{"tabindex":"0","title":"Disabled tooltip"}},[_c('a',{staticClass:"btn btn-ter btn-sma",class:_vm.$parent.getSubheaderBarItemClass(subheaderBarItem),on:{"click":function($event){return _vm.$parent.activateWorkspacebarItem(subheaderBarItem.name, $event)}}},[(
            subheaderBarItem.hasOwnProperty('swatch') &&
            _vm.$parent.currentSidePanelItem !== 'background' &&
            _vm.$parent.currentSidePanelItem !== 'background-edit' &&
            _vm.$parent.activeObjectType !== 'text'
          )?_c('div',{staticClass:"preview-swatch",class:{
            'preview-swatch-empty': _vm.$parent.activeObject[subheaderBarItem.swatch] == null,
          },style:(_vm.$parent.getSwatchStyle(_vm.$parent.activeObject[subheaderBarItem.swatch]))}):_vm._e(),(
            subheaderBarItem.hasOwnProperty('swatch') &&
            _vm.$parent.activeObjectType === 'text' &&
            subheaderBarItem.swatch === 'stroke' &&
            _vm.$parent.currentSidePanelItem !== 'background' &&
            _vm.$parent.currentSidePanelItem !== 'background-edit'
          )?_c('div',{staticClass:"preview-swatch",class:{
            'preview-swatch-empty': _vm.$parent.activeObject[subheaderBarItem.swatch] == null,
          },style:(_vm.$parent.getSwatchStyle(_vm.$parent.activeObject[subheaderBarItem.swatch]))}):_vm._e(),(
            subheaderBarItem.hasOwnProperty('swatch') &&
            _vm.$parent.activeObjectType === 'text' &&
            subheaderBarItem.swatch === 'fill' &&
            _vm.$parent.currentSidePanelItem !== 'background' &&
            _vm.$parent.currentSidePanelItem !== 'background-edit'
          )?_c('div',{staticClass:"preview-swatch",class:{
            'preview-swatch-empty': _vm.$parent.activeObject[subheaderBarItem.swatch] == null,
          },style:(_vm.$parent.getTextFillSwatchStyle(_vm.$parent.activeObject))}):_vm._e(),(
            (_vm.$parent.currentSidePanelItem === 'background' || _vm.$parent.currentSidePanelItem === 'background-edit') &&
            subheaderBarItem.swatch === 'backgroundColor'
          )?_c('div',{staticClass:"preview-swatch",style:(_vm.$parent.getBackgroundColorSwatchStyle())}):_vm._e(),_vm._v(" "+_vm._s(_vm.Lang.get(subheaderBarItem.labelLangKey))+" ")])])}),0):_vm._e(),_c('div',{attrs:{"id":"sub-header-bar-right"}},[_c('help-menu',{attrs:{"canvas-version":_vm.$store.getters.currentMagazine.canvasVersion}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }