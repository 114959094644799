<template>
  <div>
    <!-- Swatch -->
    <div
      class="color-swatch"
      :class="{
        'color-swatch-empty': this[colorSubject][colorProperty] === null,
      }"
      v-if="showPreviewSwatch === 'true'"
    >
      <div
        :style="getSwatchStyle(this[colorSubject][colorProperty])"
        v-if="this[colorSubject][colorProperty] !== null"
      ></div>
    </div>

    <!-- Remove button -->
    <a
      class="btn btn-pri color-remove"
      @click="removeColor()"
      v-if="showRemoveButton === 'true'"
    >
      <div class="icon icon-delete"></div>
      {{ Lang.get('sidepanel.labels.remove') }}
    </a>

    <!-- Color picker -->
    <ColorPicker v-model="colorPickerColor"></ColorPicker>

    <!-- Presets -->
    <div class="color-presets">
      <span class="color-presets-header">{{
        Lang.get('sidepanel.labels.greyscale-colours')
      }}</span>
      <color-preset-swatch
        presetColor="#000000"
        :lastOfRow="false"
      ></color-preset-swatch>
      <color-preset-swatch
        presetColor="#3a3b3f"
        :lastOfRow="false"
      ></color-preset-swatch>
      <color-preset-swatch
        presetColor="#747474"
        :lastOfRow="false"
      ></color-preset-swatch>
      <color-preset-swatch
        presetColor="#bdbdbd"
        :lastOfRow="false"
      ></color-preset-swatch>
      <color-preset-swatch
        presetColor="#eeeeee"
        :lastOfRow="false"
      ></color-preset-swatch>
      <color-preset-swatch
        presetColor="#ffffff"
        :lastOfRow="true"
      ></color-preset-swatch>
    </div>
    <div class="color-presets">
      <span class="color-presets-header">{{
        Lang.get('sidepanel.labels.default-colours')
      }}</span>
      <color-preset-swatch
        presetColor="#ed3833"
        :lastOfRow="false"
      ></color-preset-swatch>
      <color-preset-swatch
        presetColor="#ed3c5f"
        :lastOfRow="false"
      ></color-preset-swatch>
      <color-preset-swatch
        presetColor="#ad3fb7"
        :lastOfRow="false"
      ></color-preset-swatch>
      <color-preset-swatch
        presetColor="#7039be"
        :lastOfRow="false"
      ></color-preset-swatch>
      <color-preset-swatch
        presetColor="#3e4ebc"
        :lastOfRow="false"
      ></color-preset-swatch>
      <color-preset-swatch
        presetColor="#3096f7"
        :lastOfRow="true"
      ></color-preset-swatch>
      <color-preset-swatch
        presetColor="#3eaaf7"
        :lastOfRow="false"
      ></color-preset-swatch>
      <color-preset-swatch
        presetColor="#4fbfd8"
        :lastOfRow="false"
      ></color-preset-swatch>
      <color-preset-swatch
        presetColor="#429a89"
        :lastOfRow="false"
      ></color-preset-swatch>
      <color-preset-swatch
        presetColor="#52b443"
        :lastOfRow="false"
      ></color-preset-swatch>
      <color-preset-swatch
        presetColor="#79c635"
        :lastOfRow="false"
      ></color-preset-swatch>
      <color-preset-swatch
        presetColor="#c8de43"
        :lastOfRow="true"
      ></color-preset-swatch>
      <color-preset-swatch
        presetColor="#ffed4f"
        :lastOfRow="false"
      ></color-preset-swatch>
      <color-preset-swatch
        presetColor="#f8c145"
        :lastOfRow="false"
      ></color-preset-swatch>
      <color-preset-swatch
        presetColor="#f3923d"
        :lastOfRow="false"
      ></color-preset-swatch>
      <color-preset-swatch
        presetColor="#ed4234"
        :lastOfRow="false"
      ></color-preset-swatch>
      <color-preset-swatch
        presetColor="#7e5345"
        :lastOfRow="false"
      ></color-preset-swatch>
      <color-preset-swatch
        presetColor="#577e8d"
        :lastOfRow="true"
      ></color-preset-swatch>
    </div>
  </div>
</template>

<script>
import { bus } from '../utils/bus'
import { Chrome } from 'vue-color'
import utilities from '../mixins/utilities.js'
import Lang from '../utils/lang'

export default {
  components: { ColorPicker: Chrome },
  mixins: [utilities],
  props: [
    'colorSubject',
    'colorProperty',
    'colorCanvasType',
    'showRemoveButton',
    'showPreviewSwatch',
  ],
  data: function () {
    return {
      Lang: Lang,
      object: {},
      muteWatchers: false,
      color: null,
      colorPickerColor: 'rgba(0,0,0,0)',
    }
  },
  watch: {
    object: function () {
      if (Object.keys(this.object).length > 0) {
        this.getProperties()
      }
    },
    color: function () {
      if (!this.muteWatchers) {
        this.setColor()
        this.colorPickerColor =
          this.color !== null ? this.color : 'rgba(0,0,0,0)'
      }
    },
    colorPickerColor: function () {
      if (this.colorPickerColor !== 'rgba(0,0,0,0)') {
        this.color = this.colorPickerColor
        this.setColor()
      }
    },
  },
  computed: {
    canvas() {
      return this.$store.getters.canvas
    },
    canvasSpine() {
      return this.$store.getters.canvasSpine
    },
  },
  methods: {
    getProperties() {
      if (
        this.colorProperty === 'fill' &&
        this.$parent.$parent.activeObjectType === 'text'
      ) {
        if (this.object.isEditing === false) {
          this.selectAllText()
        }

        var selectionStart = this.object.selectionStart
        var selectionEnd = this.object.selectionEnd

        if (selectionStart === selectionEnd) {
          selectionStart =
            selectionStart > 0 ? selectionStart - 1 : selectionStart
          selectionEnd = selectionStart > 0 ? selectionEnd : 1
        }

        var selectionStyles = this.object.getSelectionStyles(
          selectionStart,
          selectionEnd,
          true
        )[0]

        if (this.isRgb(selectionStyles.fill)) {
          this.color = this.convertRgbToHex(selectionStyles.fill)
        } else {
          this.color = selectionStyles.fill
        }

        if (this.object.isEditing === false) {
          this.deselectAllText()
        }
      } else {
        if (
          this[this.colorSubject].hasOwnProperty(this.colorProperty) &&
          this[this.colorSubject][this.colorProperty] != null
        ) {
          if (this.isRgb(this[this.colorSubject][this.colorProperty])) {
            this.color = this.convertRgbToHex(
              this[this.colorSubject][this.colorProperty]
            )
          } else {
            this.color = this[this.colorSubject][this.colorProperty]
          }
        }
        if (
          this.colorSubject === 'canvas' &&
          this.colorProperty === 'backgroundColor' &&
          this.color === '#ffffff' &&
          this.colorPickerColor === 'rgba(0,0,0,0)'
        ) {
          this.colorPickerColor = 'rgba(255,255,255,1)'
        } else if (
          this.colorSubject === 'canvas' &&
          this.colorProperty === 'backgroundColor'
        ) {
          this.colorPickerColor = this.color
        }
      }
    },
    setColor() {
      if (typeof this.color === 'object' && this.color !== null) {
        this.color = this.color.hex
      }
      if (this.colorSubject === 'canvas') {
        if (this.colorCanvasType === 'spine') {
          this.canvasSpine.backgroundImage = null
          this.canvasSpine.backgroundColor = this.color
        } else {
          if (this.colorPickerColor !== 'rgba(255,255,255,1)') {
            this.canvas.backgroundImage = null
          }
          this.canvas.backgroundColor = this.color
          bus.$emit('canvasBackgroundColorChanged', this.color)
        }
      } else if (this.$parent.$parent.activeObjectType === 'text') {
        if (this.colorProperty === 'fill') {
          bus.$emit('textFillChanged', this.color)
        }
        if (this.colorProperty === 'stroke') {
          bus.$emit('textStrokeChanged', this.color)
        }
      } else {
        this[this.colorSubject][this.colorProperty] = this.color
        this[this.colorSubject].dirty = true
      }
      if (this.colorCanvasType === 'spine') {
        try {
          this.canvasSpine.requestRenderAll()
        } catch (e) {}
      } else {
        this.canvas.requestRenderAll()
        if (this.colorSubject === 'object') {
          this.canvas.fire('object:modified')
        }
      }
      if (this.colorSubject === 'object' && this.colorProperty === 'stroke') {
        if (this.color !== null && this.object.strokeWidth === 0) {
          this.object.strokeWidth = 2
          bus.$emit('strokeWidthChanged')
        }
      }
    },
    removeColor() {
      this.color = this.colorSubject === 'canvas' ? '#ffffff' : null
      this.setColor()
      if (this.colorSubject === 'object' && this.colorProperty === 'stroke') {
        this.object.strokeWidth = 0
        bus.$emit('strokeWidthChanged')
      }
    },
    usePreset(preset) {
      this.color = preset
      this.setColor()
    },
    selectAllText() {
      this.object.selectionStart = 0
      this.object.selectionEnd = this.object.text.length
    },
    deselectAllText() {
      this.object.selectionStart = 0
      this.object.selectionEnd = 0
    },
    setInputFocussed: function () {
      bus.$emit('inputFocussed', true)
    },
    setInputBlurred: function () {
      bus.$emit('inputFocussed', false)
    },
  },
  mounted() {
    let hexInput = this.$el.querySelector('.vc-input__input')
    hexInput.onfocus = () => {
      this.setInputFocussed()
    }
    hexInput.onblur = () => {
      this.setInputBlurred()
    }
    if (
      this.colorSubject === 'canvas' &&
      this.colorProperty === 'backgroundColor'
    ) {
      this.muteWatchers = true
      setTimeout(() => (this.muteWatchers = false), 500)
    }
    if (this.colorSubject === 'object') {
      if (this.colorCanvasType === 'spine') {
        var _this = this
        this.canvasSpine.getObjects().forEach(function (object) {
          _this.object = object
        })
      } else {
        this.object = this.canvas.getActiveObject()
        bus.$on('activeObjectTypeChanged', (activeObjectType) => {
          this.object =
            activeObjectType !== '' ? this.canvas.getActiveObject() : {}
        })
      }
    } else {
      if (this.colorCanvasType === 'spine') {
        if (typeof (this.canvasSpine.backgroundColor !== 'undefined')) {
          this.color = this.canvasSpine.backgroundColor
        }
      } else {
        this.getProperties()
      }
    }
    bus.$on('strokeChanged', () => {
      this.getProperties()
    })
  },
}
</script>
