module.exports = {
  'nl.subheader': {
    items: {
      text: 'Tekst',
      color: 'Kleur',
      stroke: 'Rand',
      shadow: 'Schaduw',
      filters: 'Filters',
      crop: 'Bijsnijden',
      transform: 'Transformeren',
      transparency: 'Transparantie',
      properties: 'Eigenschappen',
    },
    labels: { x: 'X', y: 'Y', w: 'B', h: 'H' },
    buttons: { 'exit-spine-editor': 'Rug editor afsluiten' },
    help: { tooltip: 'Tutorials', tour: 'Tour', youtube: 'YouTube', tutorial: 'Tutorial' },
    test: '',
  },
  'nl.sidepanel': {
    items: {
      templates: 'Sjablonen',
      background: 'Achtergrond',
      text: 'Tekst',
      images: 'Uploads',
      elements: 'Elementen',
      overview: 'Overzicht',
      spine: 'Rug',
      filters: 'Filters',
      crop: 'Bijsnijden',
      border: 'Rand',
      shadow: 'Schaduw',
      color: 'Kleur',
      properties: 'Eigenschappen',
    },
    tabs: {
      folders: 'Mijn bestanden',
      documents: 'PDF bestanden',
      templates: 'Jilster sjablonen',
      magazines: 'Mijn creaties',
      clipart: 'Clipart',
      shapes: 'Vormen',
      overview: 'Documentoverzicht',
      spine: 'Rug',
      'overview-pages': "Pagina's",
      'overview-spreads': 'Spreads',
      masks: 'Maskers',
    },
    misc: {
      pagecount: "pagina's",
      cover: 'Voorkant',
      backcover: 'Achterkant',
      'new-imagefolder': 'Nieuwe map',
      'info-imagedirectories': 'Open een map om afbeeldingen te uploaden, of maak een nieuwe map aan',
      'empty-directory': 'Je map is leeg',
      'cover-short': 'Voor',
      'backcover-short': 'Achter',
      new: 'Nieuw',
      'info-placeholders':
        "Makkelijk jouw herinnering 'inlijsten': Sleep je foto naar de 'placeholder' in een perfecte pasvorm!",
    },
    labels: {
      fontfamily: 'Lettertype',
      fontsize: 'Grootte',
      textstyle: 'Stijl',
      textalign: 'Uitlijning',
      columns: 'Kolommen',
      folders: 'Mappen',
      text: 'Tekst',
      background: 'Achtergrond',
      lineHeight: 'Regelafstand',
      charSpacing: 'Spatiëring',
      filters: {
        none: 'Geen',
        grayscale: 'Grijstinten',
        sepia: 'Sepia',
        vintage: 'Vintage',
        technicolour: 'Technicolour',
        brownie: 'Brownie',
        kodachrome: 'Kodachrome',
        polaroid: 'Polaroid',
        brightness: 'Helderheid',
        contrast: 'Contrast',
        hue: 'Kleurtoon',
        saturation: 'Verzadiging',
        blur: 'Vervaging',
      },
      'border-width': 'Breedte',
      transparency: 'Transparantie',
      'offset-x': 'Horizontale afstand',
      'offset-y': 'Verticale afstand',
      blur: 'Vervaging',
      remove: 'Verwijder',
      'greyscale-colours': 'Grijstinten',
      'default-colours': 'Standaard kleuren',
      rotation: 'Rotatie',
    },
    buttons: {
      'add-folder': 'Toevoegen',
      'add-text-title': 'Titel toevoegen',
      'add-text-subtitle': 'Ondertitel toevoegen',
      'add-text-subsection': 'Subsectie toevoegen',
      'add-text-main': 'Tekst toevoegen',
      back: 'Terug',
      'crop-apply': 'Toepassen',
      'crop-cancel': 'Annuleren',
    },
    controls: {
      'imagedirectory-open': 'Map openen',
      'imagedirectory-rename': 'Map hernoemen',
      'imagedirectory-delete': 'Map verwijderen',
      'image-dropzone-drop': 'Drag en drop om afbeeldingen naar deze map te uploaden',
      'image-dropzone-click': '...of klik om bestanden te selecteren',
      'image-uploading': 'Afbeelding uploaden',
      'pdf-dropzone-drop': 'Drag en drop om documenten naar deze map te uploaden',
      'pdf-dropzone-click': '...of klik om bestanden te selecteren',
      'pdf-uploading': 'Document uploaden',
      'imagedirectory-save': 'Opslaan',
      'image-uploading-of': 'van de',
      'image-uploading-uploaded': "foto's geüpload",
    },
  },
  'nl.canvas': {
    controls: {
      delete: 'Verwijderen',
      duplicate: 'Dupliceren',
      layers: 'Lagen',
      flip: 'Spiegelen',
      'layer-backward': 'Naar achteren',
      'layer-forward': 'Naar voren',
      'flip-horizontal': 'Draai horizontaal',
      'flip-vertical': 'Draai verticaal',
      page: 'Pagina',
      'zoom-in': 'Inzoomen',
      'zoom-out': 'Uitzoomen',
      'empty-page': 'Pagina leegmaken',
      'layer-to-last': 'Naar achtergrond',
      'layer-to-first': 'Naar voorgrond',
    },
    warnings: {
      'text-margin': 'De tekst overschrijdt de tekstmarge',
      'image-quality': 'De beeldkwaliteit is slecht.',
      'image-outside-canvas':
        'De afbeelding bevindt zich gedeeltelijk buiten het canvas en kan daardoor niet worden bijgesneden',
    },
    misc: { 'default-text-content': 'Jouw verhaal...' },
    buttons: { edit: 'Bewerken' },
  },
  'nl.modals': {
    buttons: {
      cancel: 'Annuleren',
      change: 'Wijzigen',
      submit: 'Inleveren',
      approve: 'Gereed',
      clear: 'Leegmaken',
      save: 'Opslaan',
      'open-another-page': 'Open andere pagina',
      'open-page': 'Pagina openen',
      release: 'Vrijgeven',
    },
    templates: {
      use_template_page_header: 'Gebruik deze sjabloonpagina',
      use_template_page_body:
        'Let op: De pagina die je nu bewerkt, wordt overschreven door de inhoud van de gekozen sjabloonpagina.',
      use_magazine_page_header: 'Gebruik deze pagina',
      use_magazine_page_body:
        'Let op: De pagina die je nu bewerkt, wordt overschreven door het hergebruiken van de gekozen pagina.',
    },
    page: {
      submit_magazinepage_header: 'Pagina inleveren',
      submit_magazinepage_body:
        'Wil je de pagina inleveren? Na het inleveren kan er niet meer verder gewerkt worden aan de pagina, tenzij deze weer vrijgegeven wordt.',
      approve_magazinepage_header: 'Pagina gereed maken',
      approve_magazinepage_body:
        'Wil je de pagina gereed maken? Na het gereed maken kan er niet meer aan de pagina gewerkt worden, tenzij deze weer vrijgegeven wordt.',
      clear_canvas_header: 'Maak het canvas leeg',
      clear_canvas_body: 'Weet je zeker dat je het canvas wilt wissen?',
      submitted_header: 'Deze pagina wacht op een review',
      approved_header: 'Deze pagina is gereed',
      choose_a_page_to_edit: 'Kies een pagina om te bewerken',
      open_page: 'Pagina openen',
      review_magazinepage_header: 'Review pagina',
      review_magazinepage_body:
        'Je kunt de pagina goedkeuren of vrijgeven. Na acceptatie kan de pagina niet bewerkt worden, tot de pagina wordt vrijgegeven',
      initialising_header: 'Deze pagina wordt geïnitialiseerd',
    },
    tour: {
      'intro-header': 'Welkom in de Jilster editor',
      'step-1-header': 'Kies een sjabloon',
      'step-1-text': 'Kies uit de reeks van sjablonen en pas deze  aan jouw wensen aan.',
      'step-2-header': 'Personaliseer jouw creatie met de top bar',
      'step-2-text':
        'Als je een element selecteert, verschijnt een menu boven het canvas. Je kunt daar kleuren en teksten aanpassen, afbeeldingen bijsnijden en filters geven, schaduwen toevoegen, en dimensies, posities en transparantie wijzigen.',
      'step-3-header': 'Organiseer je pagina met de side bar',
      'step-3-text':
        'Een geselecteerd element kan ook verwijderd, gedupliceerd, gespiegeld en naar voren en achteren geplaatst worden via het menu aan de linkerkant van het canvas.',
      'step-4-header': "Upload en gebruik je eigen foto's",
      'step-4-text':
        "Maak folders aan en upload je eigen foto's vanaf je computer naar de editor door te slepen of ze te selecteren vanuit een map op jouw computer. Je kunt ook PDF's uploaden en ze gebruiken als sjabloon.",
      'button-start': 'Neem een kijkje',
      'button-previous': 'Terug',
      'button-next': 'Volgende',
      'button-finish': 'Ik snap het',
      'step-of': 'van',
      'intro-text-default':
        'In deze tour leggen we uit hoe je start met jouw creatie. We begeleiden je langs de basisfuncties.',
      'intro-text-linda':
        'In deze tour wordt uitgelegd hoe je kunt starten met jouw creatie. De basisfunctionaliteiten worden aan je uitgelegd in deze rondleiding of',
      'intro-video-text-linda': 'bekijk de tutorial',
      'step-5-header': 'Verander je achtergrond en vul het met kleuren',
      'step-5-text':
        'Kies een achtergrond kiezen uit het menu aan de zijkant of vul het canvas met een effen kleur. Om die weer te vervangen, kies je gewoon een andere achtergrond uit het menu.',
      'intro-video-text-default': '',
    },
    approve: {
      header: "Hoe maak je jouw pagina's gereed en bestel je jouw creatie...?",
      subheader: 'Een stap voor stap uitleg om jouw creatie geprint en bezorgd te krijgen.',
      'step-1-part-1': 'Als je de pagina af hebt, klik dan op de knop "Gereed" rechtsboven in de editor.',
      'step-1-part-2': 'Hoe doe je dit?',
      'step-1-part-3': "Alle pagina's, inclusief de voorkant, de achterkant en de rug moeten gereed gemaakt zijn.",
      'step-2': "In het paginaoverzicht zie je dan het 'gereed' icoontje onder de pagina staan.",
      submitted: 'Ingeleverd',
      approved: 'Gereed',
      'step-3': "Hebben alle pagina's een groen vinkje. Dan ben je klaar en kun je jouw creatie bestellen.",
    },
    spine: { 'edit-text': 'Tekst bewerken' },
    margins: {
      header: 'Voorkom afdrukproblemen',
      text: 'Voorkom witte randen: Trek de achtergrondfoto door tot de\nbolletjes half zichtbaar zijn.',
    },
  },
  'nl.header': {
    buttons: { save: 'Opslaan', submit: 'Inleveren', approve: 'Gereed', edit: 'Vrijgeven', review: 'Review' },
    messages: { page_is_submitted: 'De pagina is ingeleverd', page_is_approved: 'De pagina is gereed' },
    labels: { title: 'Titel' },
  },
  'nl.alarms': {
    'edit-spine':
      'Deze carrier heeft een rug die je kunt personaliseren.\nVergeet deze niet gereed te maken om te bestellen.',
    'pdf-importing':
      'Uw PDF is succesvol geüpload en wordt nu verwerkt. Als dit gelukt is verschijnt de PDF hier automatisch. Dit kan een paar minuten duren.',
    'image-in-use': 'Deze afbeelding kan niet worden verwijderd omdat deze in gebruik is',
    'edit-spine-link': 'Hoe maak je de rug of een pagina gereed?',
    'pdf-info':
      'Let op: Je kunt het beste jouw pagina in Jilster opmaken. Wil je een PDF uploaden, zet deze dan om in een PNG-bestand en upload deze als afbeelding. Zo voorkom je onscherpte van de pagina. ',
    'image-info':
      'Wist je dat je afbeeldingen kunt uploaden met de Jilster app? Download hem nu in de AppStore of Google PlayStore.',
  },
  'nl.maintenance': {
    header: 'Offline voor periodiek onderhoud',
    text: 'We zijn snel weer online',
    button: 'Ga naar de website',
  },
  'nl.test': { test: '' },
  'de.subheader': {
    items: {
      text: 'Text',
      color: 'Farbe',
      stroke: 'Rand',
      shadow: 'Schatten',
      filters: 'Filter',
      crop: 'Zuschneiden',
      transform: 'Verwandeln',
      transparency: 'Transparenz',
      properties: 'Eigenschaften',
    },
    labels: { x: 'X', y: 'Y', w: 'W', h: 'H' },
    buttons: { 'exit-spine-editor': 'Rückentext-Editor beenden' },
    help: { tooltip: 'Tutorials', tour: 'Tour', youtube: 'YouTube', tutorial: 'Tutorial' },
    test: 'Prüfung',
  },
  'de.sidepanel': {
    items: {
      templates: 'Vorlagen',
      background: 'Hintergrund',
      text: 'Text',
      images: 'Uploads',
      elements: 'Elemente',
      overview: 'Übersicht',
      spine: 'Rückentext',
      filters: 'Filter',
      crop: 'Zuschneiden',
      border: 'Rand',
      shadow: 'Schatten',
      color: 'Farbe',
      properties: 'Eigenschaften',
    },
    tabs: {
      folders: 'Meine Ordner',
      documents: 'PDF-Dateien',
      templates: 'Jilster-Vorlagen',
      magazines: 'Meine Kreationen',
      clipart: 'Clipart',
      shapes: 'Formen',
      overview: 'Dokumentenübersicht',
      spine: 'Rückentext',
      'overview-pages': 'Page view',
      'overview-spreads': 'Spread view',
      masks: 'Masks',
    },
    misc: {
      pagecount: 'Seiten',
      cover: 'Cover',
      backcover: 'Rückseite',
      'new-imagefolder': 'Neuer Ordner',
      'info-imagedirectories': 'Öffnen Sie einen Ordner, um Bilder hochzuladen, oder erstellen Sie einen neuen Ordner',
      'empty-directory': 'Ihr Ordner ist leer',
      'cover-short': 'Cover',
      'backcover-short': 'Rückseite',
      new: 'Neu',
      'info-placeholders':
        'Gestalte deine Erinnerungen ganz einfach: Ziehe die Fotos per Drag & Drop in die Platzhalter und passe sie perfekt an!',
    },
    labels: {
      fontfamily: 'Schriftart',
      fontsize: 'Schriftgröße',
      textstyle: 'Schriftstil',
      textalign: 'Ausrichten',
      columns: 'Spalten',
      folders: 'Ordner',
      text: 'Text',
      background: 'Hintergrund',
      lineHeight: 'Zeilenhöhe',
      charSpacing: 'Buchstaben-Abstand',
      filters: {
        none: 'None',
        grayscale: 'Grautöne',
        sepia: 'Sepia',
        vintage: 'Vintage',
        technicolour: 'Technicolour',
        brownie: 'Brownie',
        kodachrome: 'Kodachrome',
        polaroid: 'Polaroid',
        brightness: 'Helligkeit',
        contrast: 'Kontrast',
        hue: 'Farbton',
        saturation: 'Sättigung',
        blur: 'Weichzeichnen',
      },
      'border-width': 'Width',
      transparency: 'Transparenz',
      'offset-x': 'Horizontal',
      'offset-y': 'Vertikal',
      blur: 'Weichzeichnen',
      remove: 'Remove',
      'greyscale-colours': 'Greyscale colours',
      'default-colours': 'Standardfarben',
      rotation: 'Rotation',
    },
    buttons: {
      'add-folder': 'Neuer Ordner',
      'add-text-title': 'Titel einfügen',
      'add-text-subtitle': 'Untertitel einfügen',
      'add-text-subsection': 'Unterabschnitt einfügen',
      'add-text-main': 'Haupttext einfügen',
      back: 'Zurück',
      'crop-apply': 'Bewerben',
      'crop-cancel': 'Abbrechen',
    },
    controls: {
      'imagedirectory-open': 'Ordner öffnen',
      'imagedirectory-rename': 'Ordner umbenennen',
      'imagedirectory-delete': 'Ordner löschen',
      'image-dropzone-drop': 'Nutze "drag and drop", um Bilder in diesen Ordner hochzuladen.',
      'image-dropzone-click': '... oder klicke, um Dateien auszuwählen.',
      'image-uploading': 'Bild lädt hoch',
      'pdf-dropzone-drop': 'Nutze "drag and drop", um Dokumente in diesen Ordner hochzuladen.',
      'pdf-dropzone-click': '... oder klicke, um Dateien auszuwählen.',
      'pdf-uploading': 'Dokument lädt hoch',
      'imagedirectory-save': 'Speichern',
      'image-uploading-of': 'of',
      'image-uploading-uploaded': 'pictures uploaded',
    },
  },
  'de.canvas': {
    controls: {
      delete: 'Löschen',
      duplicate: 'Kopieren',
      layers: 'Ebenen',
      flip: 'Spiegeln',
      'layer-backward': 'Nach hinten',
      'layer-forward': 'Nach vorne',
      'flip-horizontal': 'Horizontal spiegeln',
      'flip-vertical': 'Vertikal spiegeln',
      page: 'Seite',
      'zoom-in': 'Hineinzoomen',
      'zoom-out': 'Rauszoomen',
      'empty-page': 'Leere Seite',
      'layer-to-last': 'In den Hintergrund',
      'layer-to-first': 'Ganz nach vorn',
    },
    warnings: {
      'text-margin': 'Der Text geht über den Textrand hinaus.',
      'image-quality': 'Die Bildqualität ist schlecht.',
      'image-outside-canvas': 'Das Bild liegt teilweise außerhalb der Leinwand und kann nicht zugeschnitten werden',
    },
    misc: { 'default-text-content': 'Deine Geschichte ...' },
    buttons: { edit: 'Bearbeiten' },
  },
  'de.modals': {
    buttons: {
      cancel: 'Abbrechen',
      change: 'Ändern',
      submit: 'Einreichen',
      approve: 'Genehmigen',
      clear: 'Löschen',
      save: 'Speichern',
      'open-another-page': 'Öffnen Sie eine andere Seite',
      'open-page': 'Seite öffnen',
      release: 'Freigeben',
    },
    templates: {
      use_template_page_header: 'Vorlagenseite verwenden',
      use_template_page_body: 'Dein aktuelles Design wird durch den Inhalt dieser Vorlagenseite überschrieben.',
      use_magazine_page_header: 'Diese Magazinseite verwenden',
      use_magazine_page_body: 'Dein aktuelles Design wird durch den Inhalt dieser Magazinseite überschrieben.',
    },
    page: {
      submit_magazinepage_header: 'Seite einreichen',
      submit_magazinepage_body:
        'Möchtest du die Seite einreichen? Nachdem du die Seite eingereicht hast, kannst du sie nicht mehr bearbeiten, bis der Chefredakteur sie freigibt.',
      approve_magazinepage_header: 'Seite genehmigen',
      approve_magazinepage_body:
        'Ist die Seite wirklich fertig? Nach der Genehmigung kann sie nicht mehr bearbeitet werden, es sei denn, sie wird wieder freigegeben.',
      clear_canvas_header: 'Gesamte Seite löschen',
      clear_canvas_body: 'Bist du sicher, dass du die gesamte Seite löschen möchtest?',
      submitted_header: 'Diese Seite wartet darauf, überprüft zu werden',
      approved_header: 'Diese Seite ist genehmigt',
      choose_a_page_to_edit: 'Wähle eine Seite zum Bearbeiten aus',
      open_page: 'Seite öffnen',
      review_magazinepage_header: 'Seite überprüfen',
      review_magazinepage_body:
        'Du kannst die Seite genehmigen oder freigeben. Nach der Annahme kann die Seite nicht mehr bearbeitet werden, bis sie freigegeben wird.',
      initialising_header: 'Diese Seite wird gerade eingerichtet',
    },
    tour: {
      'intro-header': 'Willkommen im Jilster-Editor',
      'step-1-header': 'Wähle eine Vorlage',
      'step-1-text':
        'Wähle eine Vorlage aus unserem Katalog und beginne mit der Bearbeitung, um sie an deine Wünsche anzupassen.',
      'step-2-header': 'Personalisiere dein Design in der oberen Leiste',
      'step-2-text':
        'Wenn du ein Element auswählst, erscheint über der Seite ein Menü. Du kannst Farben und Texte ändern, Bilder zuschneiden, Filter verwenden, Schatten hinzufügen, Abmessungen und Positionen festlegen und die Transparenz ändern.',
      'step-3-header': 'Organisiere deine Seite in der Seitenleiste',
      'step-3-text':
        'Ein ausgewähltes Element kann auch entfernt, dupliziert, gespiegelt oder über die Werkzeugleiste auf der linken Seite der Leinwand vorwärts und rückwärts verschoben werden.',
      'step-4-header': 'Eigene Fotos hochladen und verwenden',
      'step-4-text':
        'Du kannst Ordner erstellen und deine eigenen Fotos hochladen, indem du sie von deinem Computer ziehst oder aus einem Ordner auf deinem Computer auswählst. Du kannst auch eine PDF-Datei hochladen, um sie als Vorlage zu verwenden.',
      'button-start': 'Schau mal',
      'button-previous': 'Zurück',
      'button-next': 'Nächste',
      'button-finish': 'Habe es',
      'step-of': 'von',
      'intro-text-default':
        'Folge dieser Tour, um mit deiner Kreation zu beginnen. Du wirst durch die grundlegenden Funktionalitäten geführt.',
      'intro-text-linda':
        'Folge dieser Tour, um mit deiner Kreation zu beginnen. Du wirst durch die grundlegenden Funktionalitäten geführt oder',
      'intro-video-text-linda': 'Schau dir das Tutorial an',
      'step-5-header': 'Change your background and fill it with colors',
      'step-5-text':
        'Wähle einen Hintergrund aus dem Seitenmenü oder fülle die Seite mit einer Farbe. Um ihn wieder zu ersetzen, wählst du einfach einen anderen Hintergrund aus dem Menü.',
      'intro-video-text-default': '',
    },
    approve: {
      header: 'Wie du Seiten genehmigst und deine Kreation bestellst',
      subheader:
        'Eine Schritt-für-Schritt-Anleitung, deine Kreation drucken und bis zu deiner Fußmatte liefern zu lassen',
      'step-1-part-1':
        'Alle Seiten, inklusive des Covers auf der Vorder- und der Rückseite sowie der Rücken müssen genehmigt sein.',
      'step-1-part-2': 'Wie kannst du das tun?',
      'step-1-part-3':
        'Wenn du eine Seite fertiggestellt hast, drücke den Button "Genehmigen", den du oben rechts im Editor siehst.',
      'step-2':
        'Das Genehmigt-Icon siehst du unterhalb der Seiten in der Seitenübersicht. Bei allen Seiten muss dieses Icon vor der Seitenzahl stehen.',
      submitted: 'Eingereicht',
      approved: 'Genehmigt',
      'step-3': 'Fertig. Deine Kreation ist bereit, bestellt zu werden.',
    },
    spine: { 'edit-text': 'Text bearbeiten' },
    margins: {
      header: 'Druckprobleme vermeiden',
      text: 'Bildhintergründe über den Anschnittbereich erweitern um weiße Ränder zu ver- meiden',
    },
  },
  'de.header': {
    buttons: {
      save: 'Speichern',
      submit: 'Einreichen',
      approve: 'Genehmigen',
      edit: 'Bearbeiten',
      review: 'Überprüfung',
    },
    messages: { page_is_submitted: 'Die Seite wurde eingereicht.', page_is_approved: 'Die Seite wurde genehmigt.' },
    labels: { title: 'Titel' },
  },
  'de.alarms': {
    'edit-spine': "This carrier has a spine which you can personalise. Don't forget to approve before you order.",
    'pdf-importing':
      'Dein PDF wurde erfolgreich hochgeladen und wird nun verarbeitet. Es erscheint hier automatisch, wenn es fertig ist. Bitte schauen Sie in ein paar Minuten noch einmal vorbei',
    'image-in-use': 'Dieses Bild kann nicht gelöscht werden, da es verwendet wird',
    'edit-spine-link': 'How to approve the spine or a page?',
    'pdf-info':
      'Hinweis: Es ist am besten, wenn du deine Seite in Jilster gestaltest. Willst du ein PDF hochladen? Dann wandle sie in eine PNG-Datei um und lade sie als Bild hoch. So vermeidest du Unschärfen auf der Seite.',
    'image-info':
      'Wusstest du, dass du mit der Jilster-App Bilder hochladen kannst? Lade sie jetzt im AppStore oder Google PlayStore herunter.',
  },
  'de.maintenance': {
    header: 'Wegen Wartung außer Betrieb',
    text: 'Wir werden bald zurück sein',
    button: 'Zur Website gehen',
  },
  'de.test': { test: '' },
  'fr.alarms': {
    'edit-spine':
      "Le LINDA. a une tranche que vous pouvez personnaliser. N'oubliez pas d'approuver avant de commander.\n",
    'edit-spine-link': 'Comment approuver la tranche ou une page ?',
    'pdf-importing':
      'Votre PDF a été téléchargé avec succès et est en cours de traitement. Il apparaîtra ici automatiquement une fois terminé. Veuillez réessayer dans quelques minutes.',
    'image-in-use': "Cette image ne peut pas être supprimée car elle est en cours d'utilisation.\n",
    'pdf-info': '',
    'image-info': '',
  },
  'fr.canvas': {
    controls: {
      delete: 'Supprimer',
      duplicate: 'Dupliquer',
      layers: 'Couches',
      'layer-backward': 'En arrière',
      'layer-forward': 'En avant\n',
      flip: 'Retourner',
      'flip-horizontal': 'Retourner horizontalement',
      'flip-vertical': 'Retourner verticalement',
      page: 'Page',
      'zoom-in': 'Zoomer',
      'zoom-out': 'Dézoomer',
      'empty-page': 'Page vide',
      'layer-to-last': '',
      'layer-to-first': '',
    },
    warnings: {
      'text-margin': 'Le texte dépasse la marge de texte',
      'image-quality': "La qualité de l'image est mauvaise",
      'image-outside-canvas': "L'image est partiellement en dehors de la toile, elle ne peut pas être recadrée.",
    },
    misc: { 'default-text-content': 'Votre histoire...' },
    buttons: { edit: 'Modifier' },
  },
  'fr.header': {
    buttons: { save: 'Sauvegarder', submit: 'Soumettre', approve: 'Approuver', edit: 'Libérer', review: 'Réviser' },
    messages: { page_is_submitted: 'La page est soumise', page_is_approved: 'La page est approuvée' },
    labels: { title: 'Titre' },
  },
  'fr.modals': {
    buttons: {
      cancel: 'Annuler',
      save: 'Sauvegarder',
      change: 'Changer',
      submit: 'Soumettre',
      approve: 'Approuver',
      clear: 'Effacer',
      'open-another-page': 'Ouvrir une autre page',
      'open-page': 'Ouvrir la page',
      release: 'Libérer',
    },
    templates: {
      use_template_page_header: 'Utilisez ce modèle de page\n',
      use_template_page_body: 'Votre design actuel sera écrasé par le contenu de cette page de magazine.',
      use_magazine_page_header: 'Utiliser cette page de magazine',
      use_magazine_page_body: 'Votre design actuel sera écrasé par le contenu de cette page de magazine.\n',
    },
    page: {
      submit_magazinepage_header: 'Soumettre la page',
      submit_magazinepage_body:
        "Voulez-vous soumettre la page ? Après avoir soumis la page, vous ne pouvez plus travailler dessus, sauf si l'éditeur principal la soumet.\n",
      approve_magazinepage_header: 'Approuver la page',
      approve_magazinepage_body:
        'Voulez-vous approuver la page ? Après avoir approuvé la page, vous ne pouvez plus travailler dessus, sauf si elle est déverrouillée.',
      clear_canvas_header: 'Effacer la toile',
      clear_canvas_body: 'Êtes-vous sûr de vouloir effacer la toile ?\n',
      submitted_header: "Cette page est en attente d'être révisée",
      approved_header: 'Cette page est approuvée',
      choose_a_page_to_edit: 'Choisissez une page à modifier',
      open_page: 'Ouvrir la page',
      review_magazinepage_header: 'Réviser la page',
      review_magazinepage_body:
        "Vous pouvez approuver ou libérer la page. Après avoir approuvé, elle sera bloquée jusqu'à ce qu'elle soit à nouveau libérée.",
      initialising_header: '',
    },
    tour: {
      'intro-header': "Bienvenue dans l'éditeur Jilster",
      'intro-text-default':
        'Dans cette visite, nous vous montrerons comment démarrer votre création. Nous vous y présenterons les fonctions de base.\n',
      'intro-text-linda':
        'Suivez cette visite pour savoir comment commencer votre création. Nous vous y présenterons les fonctionnalités de base ou',
      'intro-video-text-linda': 'regardez le tutoriel',
      'step-1-header': 'Choisissez un modèle',
      'step-1-text': 'Choisissez parmi la gamme de modèles et personnalisez-les selon vos besoins.\n',
      'step-2-header': 'Personnalisez votre design avec la barre supérieure',
      'step-2-text':
        'Lorsque vous sélectionnez un élément, un menu apparaît au-dessus de la toile. Vous pouvez y ajuster les couleurs et les textes, recadrer et filtrer les images, ajouter des ombres et modifier les dimensions, la position et la transparence.\n',
      'step-3-header': 'Organisez votre page avec la barre latérale',
      'step-3-text':
        "Un élément sélectionné peut être supprimé, dupliqué, retourné et déplacé au premier ou au dernier plan grâce au à la barre d'outils située à gauche de la toile.\n",
      'step-4-header': 'Téléchargez et utilisez vos propres photos',
      'step-4-text':
        "Créez des dossiers et importez vos propres photos depuis votre ordinateur dans l'éditeur en les faisant glisser ou en les sélectionnant dans un dossier de votre ordinateur. Vous pouvez également télécharger des fichiers PDF et les utiliser comme modèles.\n",
      'step-5-header': 'Changez votre fond et remplissez-le de couleurs',
      'step-5-text':
        'Choisissez un fond dans le menu latéral ou remplissez la toile avec une couleur solide. Pour le remplacer à nouveau, il suffit de choisir un autre fond dans le menu.\n',
      'step-of': 'de',
      'button-start': 'Regarder',
      'button-previous': 'Retour',
      'button-next': 'Suivant',
      'button-finish': "J'ai compris",
      'intro-video-text-default': '',
    },
    approve: {
      header: 'Comment approuver les pages et commander votre création...\n',
      subheader: 'Une explication étape par étape pour obtenir votre création imprimée et livrée à votre porte.',
      submitted: 'Soumis',
      approved: 'Approuver',
      'step-1-part-1': 'Toutes les pages, y compris le recto, le verso et la tranche, doivent être approuvées.\n',
      'step-1-part-2': 'Comment faire ?\n',
      'step-1-part-3':
        "Lorsque vous avez terminé la page, cliquez sur le bouton 'Approuver' situé dans l'en-tête de l'éditeur.",
      'step-2':
        "Vous verrez l'icône 'approuver' sous les pages dans la vue d'ensemble du document. Toutes les pages doivent avoir cette icône devant le numéro de page.",
      'step-3': "C'est fait. Votre création est prête à être commandée.\n",
    },
    spine: { 'edit-text': 'Modifier le texte' },
    margins: {
      header: "Éviter les problèmes d'impression",
      text: 'Assurez-vous que les arrière-plans et les images étendent la zone de fond perdu pour éviter les marges blanches.',
    },
  },
  'fr.sidepanel': {
    items: {
      templates: 'Modèles',
      background: 'Fond',
      text: 'Texte',
      elements: 'Éléments',
      images: 'Téléchargements',
      overview: 'Aperçu',
      spine: 'Tranche',
      filters: 'Filtres',
      crop: 'Recadrer',
      border: 'Contour',
      shadow: 'Ombre',
      color: 'Couleur',
      properties: 'Propriétés',
    },
    buttons: {
      'add-folder': 'Ajouter le dossier',
      'add-text-title': 'Ajouter un titre',
      'add-text-subtitle': 'Ajouter un sous-titre',
      'add-text-subsection': 'Ajouter une sous-section',
      'add-text-main': 'Ajouter le texte principal',
      back: 'Retour',
      'crop-apply': 'Appliquer',
      'crop-cancel': 'Annuler',
    },
    tabs: {
      folders: 'Mes dossiers',
      documents: 'Fichiers PDF',
      templates: 'Modèles Jilster',
      magazines: 'Mes créations',
      clipart: 'Clipart',
      shapes: 'Formes',
      overview: 'Aperçu des documents',
      'overview-pages': 'Vue de la page',
      'overview-spreads': 'Vue étendue',
      spine: 'Tranche',
      masks: 'Masks',
    },
    labels: {
      fontfamily: 'Police',
      fontsize: 'Taille',
      textstyle: 'Style de texte',
      textalign: 'Aligner',
      lineHeight: 'Hauteur de la ligne',
      charSpacing: 'Espacement des lettres',
      columns: 'Colonnes',
      folders: 'Dossiers',
      text: 'Texte',
      background: 'Fond',
      filters: {
        none: 'Aucun',
        grayscale: 'Niveaux de gris',
        sepia: 'Sépia',
        vintage: 'Vintage',
        technicolour: 'Technicolor',
        brownie: 'Brownie',
        kodachrome: 'Kodachrome',
        polaroid: 'Polaroïd',
        brightness: 'Luminosité',
        contrast: 'Contraste',
        hue: 'Teinte',
        saturation: 'Saturation',
        blur: 'Flou',
      },
      'border-width': 'Largeur',
      transparency: 'Transparence',
      'offset-x': 'Distance horizontale',
      'offset-y': 'Distance verticale',
      blur: 'Flou',
      remove: 'Enlever',
      'greyscale-colours': 'Niveaux de gris',
      'default-colours': 'Couleurs par défaut',
      rotation: 'Rotation',
    },
    controls: {
      'imagedirectory-open': 'Ouvrir le dossier',
      'imagedirectory-rename': 'Renommer le dossier',
      'imagedirectory-delete': 'Supprimer le dossier',
      'imagedirectory-save': 'Sauvegarder',
      'image-dropzone-drop': 'Faites un glisser-déposer pour importer des images dans ce dossier\n',
      'image-dropzone-click': '...ou cliquez pour sélectionner des fichiers',
      'image-uploading': 'Importer une image',
      'pdf-dropzone-drop': 'Faites un glisser-déposer pour importer des images dans ce dossier',
      'pdf-dropzone-click': '...ou cliquez pour sélectionner des fichiers',
      'pdf-uploading': "Document en cours d'importation",
      'image-uploading-of': 'de',
      'image-uploading-uploaded': 'photos importées',
    },
    misc: {
      pagecount: 'pages',
      cover: 'Couverture arrière',
      'cover-short': 'Couverture',
      backcover: 'Couverture arrière',
      'backcover-short': 'Couverture arrière',
      'new-imagefolder': 'Nouveau dossier',
      'info-imagedirectories': 'Ouvrez un dossier pour télécharger des images ou créez un nouveau dossier.\n',
      'empty-directory': 'Votre dossier est vide',
      new: 'Nouveau',
      'info-placeholders':
        'Encadrez facilement vos souvenirs: Glissez et déposez vos photos dans les espaces prévus à cet effet et personnalisez le cadre parfait!',
    },
  },
  'fr.subheader': {
    items: {
      text: 'Texte',
      color: 'Couleur',
      stroke: 'Contour',
      shadow: 'Ombre',
      filters: 'Filtres',
      crop: 'Recadrer',
      transform: 'Transformer',
      transparency: 'Transparence',
      properties: 'Propriétés',
    },
    help: { tooltip: 'Tutoriels', tour: 'Visite', youtube: 'YouTube', tutorial: 'Tutoriel' },
    buttons: { 'exit-spine-editor': "Quitter l'éditeur de la tranche" },
    labels: { x: 'X', y: 'Y', w: 'W', h: 'H' },
    test: '',
  },
  'fr.maintenance': {
    header: 'Hors ligne pour cause de maintenance',
    text: 'Nous serons bientôt de retour',
    button: 'Aller sur le site',
  },
  'fr.test': { test: '' },
  'es.alarms': {
    'edit-spine': '',
    'edit-spine-link': '',
    'pdf-importing': '',
    'image-in-use': '',
    'pdf-info': '',
    'image-info': '',
  },
  'es.canvas': {
    controls: {
      delete: '',
      duplicate: '',
      layers: '',
      'layer-backward': '',
      'layer-forward': '',
      flip: '',
      'flip-horizontal': '',
      'flip-vertical': '',
      page: '',
      'zoom-in': '',
      'zoom-out': '',
      'empty-page': '',
      'layer-to-last': '',
      'layer-to-first': '',
    },
    warnings: { 'text-margin': '', 'image-quality': '', 'image-outside-canvas': '' },
    misc: { 'default-text-content': '' },
    buttons: { edit: '' },
  },
  'es.header': {
    buttons: { save: '', submit: '', approve: '', edit: '', review: 'Review' },
    messages: { page_is_submitted: '', page_is_approved: '' },
    labels: { title: '' },
  },
  'es.modals': {
    buttons: {
      cancel: '',
      save: '',
      change: '',
      submit: '',
      approve: '',
      clear: '',
      'open-another-page': '',
      'open-page': '',
      release: '',
    },
    templates: {
      use_template_page_header: '',
      use_template_page_body: '',
      use_magazine_page_header: '',
      use_magazine_page_body: '',
    },
    page: {
      submit_magazinepage_header: '',
      submit_magazinepage_body: '',
      approve_magazinepage_header: '',
      approve_magazinepage_body: '',
      clear_canvas_header: '',
      clear_canvas_body: '',
      submitted_header: '',
      approved_header: '',
      choose_a_page_to_edit: '',
      open_page: 'Abrir página',
      review_magazinepage_header: 'Review page\n',
      review_magazinepage_body:
        'You can approve or release the page. After approving, it will be blocked until is released.',
      initialising_header: '',
    },
    tour: {
      'intro-header': '',
      'intro-text-default': '',
      'intro-text-linda': '',
      'intro-video-text-linda': '',
      'step-1-header': '',
      'step-1-text': '',
      'step-2-header': '',
      'step-2-text': '',
      'step-3-header': '',
      'step-3-text': '',
      'step-4-header': '',
      'step-4-text': '',
      'step-5-header': '',
      'step-5-text': '',
      'step-of': '',
      'button-start': '',
      'button-previous': '',
      'button-next': '',
      'button-finish': '',
      'intro-video-text-default': '',
    },
    approve: {
      header: '',
      subheader: '',
      submitted: '',
      approved: '',
      'step-1-part-1': '',
      'step-1-part-2': '',
      'step-1-part-3': '',
      'step-2': '',
      'step-3': '',
    },
    spine: { 'edit-text': '' },
    margins: { header: '', text: '' },
  },
  'es.sidepanel': {
    items: {
      templates: '',
      background: '',
      text: '',
      elements: '',
      images: '',
      overview: '',
      spine: '',
      filters: '',
      crop: '',
      border: '',
      shadow: '',
      color: '',
      properties: 'Propiedades',
    },
    buttons: {
      'add-folder': '',
      'add-text-title': '',
      'add-text-subtitle': '',
      'add-text-subsection': '',
      'add-text-main': '',
      back: '',
      'crop-apply': '',
      'crop-cancel': '',
    },
    tabs: {
      folders: '',
      documents: '',
      templates: '',
      magazines: '',
      clipart: '',
      shapes: '',
      overview: '',
      'overview-pages': '',
      'overview-spreads': '',
      spine: '',
      masks: 'Masks',
    },
    labels: {
      fontfamily: '',
      fontsize: '',
      textstyle: '',
      textalign: '',
      lineHeight: '',
      charSpacing: '',
      columns: '',
      folders: '',
      text: '',
      background: '',
      filters: {
        none: '',
        grayscale: '',
        sepia: '',
        vintage: '',
        technicolour: '',
        brownie: '',
        kodachrome: '',
        polaroid: '',
        brightness: '',
        contrast: '',
        hue: '',
        saturation: '',
        blur: '',
      },
      'border-width': '',
      transparency: '',
      'offset-x': '',
      'offset-y': '',
      blur: '',
      remove: '',
      'greyscale-colours': '',
      'default-colours': '',
      rotation: 'Rotation',
    },
    controls: {
      'imagedirectory-open': '',
      'imagedirectory-rename': '',
      'imagedirectory-delete': '',
      'imagedirectory-save': '',
      'image-dropzone-drop': '',
      'image-dropzone-click': '',
      'image-uploading': '',
      'pdf-dropzone-drop': '',
      'pdf-dropzone-click': '',
      'pdf-uploading': '',
      'image-uploading-of': '',
      'image-uploading-uploaded': '',
    },
    misc: {
      pagecount: '',
      cover: '',
      'cover-short': '',
      backcover: '',
      'backcover-short': '',
      'new-imagefolder': '',
      'info-imagedirectories': '',
      'empty-directory': '',
      new: '',
      'info-placeholders': '',
    },
  },
  'es.subheader': {
    items: {
      text: '',
      color: '',
      stroke: '',
      shadow: '',
      filters: '',
      crop: '',
      transform: '',
      transparency: '',
      properties: 'Propiedades',
    },
    help: { tooltip: '', tour: '', youtube: '', tutorial: '' },
    buttons: { 'exit-spine-editor': '' },
    labels: { x: '', y: '', w: '', h: '' },
    test: '',
  },
  'es.maintenance': { header: 'Down for maintenance', text: 'We will be back soon', button: 'Go to the website' },
  'es.test': { test: '' },
  'en.alarms': {
    'edit-spine': "This carrier has a spine which you can personalise. Don't forget to approve before you order.",
    'edit-spine-link': 'How to approve the spine or a page?',
    'image-in-use': 'This image cannot be deleted because it is in use',
    'pdf-importing':
      'Your PDF was successfully uploaded and is now being processed. It will appear here automatically when finished. Please check back in a couple of minutes',
    'pdf-info':
      'Note: It is best to design your page in Jilster. Do you want to upload a PDF? Convert it to a PNG file and upload it as an image. This will prevent blurriness on the page.',
    'image-info':
      'Did you know you can upload images with the Jilster app? Download it in the AppStore or Google PlayStore now.',
  },
  'en.canvas': {
    buttons: { edit: 'Edit' },
    controls: {
      delete: 'Delete',
      duplicate: 'Duplicate',
      'empty-page': 'Empty page',
      flip: 'Flip',
      'flip-horizontal': 'Flip horizontal',
      'flip-vertical': 'Flip vertical',
      'layer-backward': 'Backward',
      'layer-forward': 'Forward',
      'layer-to-last': 'Send to back',
      'layer-to-first': 'Bring to front',
      layers: 'Layers',
      page: 'Page',
      'zoom-in': 'Zoom in',
      'zoom-out': 'Zoom out',
    },
    misc: { 'default-text-content': 'Your story...' },
    warnings: {
      'image-outside-canvas': 'The image is partially outside of the canvas, it cannot be cropped',
      'image-quality': 'The image quality is bad',
      'text-margin': 'The text exceeds the text margin',
    },
  },
  'en.header': {
    buttons: { approve: 'Approve', edit: 'Release', review: 'Review', save: 'Save', submit: 'Submit' },
    labels: { title: 'Title' },
    messages: { page_is_approved: 'The page is approved', page_is_submitted: 'The page is submitted' },
  },
  'en.maintenance': { button: 'Go to the website', header: 'Down for maintenance', text: 'We will be back soon' },
  'en.modals': {
    approve: {
      approved: 'Approved',
      header: 'How to approve pages and order your creation...',
      'step-1-part-1': 'All the pages, including the front, back cover and spine need to be approved.',
      'step-1-part-2': 'How can you do that?',
      'step-1-part-3': 'When you finished the page, hit the "Approve" button placed in the header of the editor.',
      'step-2':
        "You'll see the approve icon beneath the pages in the Document Overview. All the pages need to have this icon in front of the page number.",
      'step-3': 'Done. Your creation is ready to be ordered.',
      subheader: 'A step by step explanation to get your creation printed and delivered to your doorstep.',
      submitted: 'Submitted',
    },
    buttons: {
      approve: 'Approve',
      cancel: 'Cancel',
      change: 'Change',
      clear: 'Clear',
      'open-another-page': 'Open another page',
      'open-page': 'Open page',
      release: 'Release',
      save: 'Save',
      submit: 'Submit',
    },
    margins: {
      header: 'Avoid print issues',
      text: 'Make sure backgrounds and images extend the bleed area, to avoid white margins.',
    },
    page: {
      approve_magazinepage_body:
        "Do you want to approve the page? After approving the page you can't work on it unless it's unlocked.",
      approve_magazinepage_header: 'Approve the page',
      approved_header: 'This page is approved',
      choose_a_page_to_edit: 'Choose a page to edit',
      clear_canvas_body: 'Are you sure you want to clear the canvas?',
      clear_canvas_header: 'Clear the canvas',
      open_page: 'Open page',
      review_magazinepage_body:
        'You can approve or release the page. After approving, it will be blocked until is released.',
      review_magazinepage_header: 'Review page',
      submit_magazinepage_body:
        "Do you want to submit the page? After submitting the page you can't work on it unless the chief editor submits it.",
      submit_magazinepage_header: 'Submit the page',
      submitted_header: 'This page is waiting to be reviewed',
      initialising_header: 'This page is being setup',
    },
    spine: { 'edit-text': 'Edit Text' },
    templates: {
      use_magazine_page_body: 'Your current design will be overwritten by the content of this magazine page.',
      use_magazine_page_header: 'Use this magazine page',
      use_template_page_body: 'Your current design will be overwritten by the content of this template page.',
      use_template_page_header: 'Use this template page',
    },
    tour: {
      'button-finish': 'Got it',
      'button-next': 'Next',
      'button-previous': 'Back',
      'button-start': 'Take a look',
      'intro-header': 'Welcome to the Jilster editor',
      'intro-text-default':
        'In this tour, we will show you how to get started with your creation. We will guide you through the basic functions.',
      'intro-text-linda':
        'Follow this tour on how to get started with your creation. You will be guided through the basic functionalities or',
      'intro-video-text-default': 'view the tutorial',
      'intro-video-text-linda': 'view the tutorial',
      'step-1-header': 'Choose a template',
      'step-1-text': 'Choose from the range of templates and customise them to your needs.',
      'step-2-header': 'Personalise your design with the topbar',
      'step-2-text':
        'When you select an element, a menu appears above the canvas. There you can adjust colours and texts, crop and filter images, add shadows, and change dimensions, positions and transparency.',
      'step-3-header': 'Organise your page with the sidebar',
      'step-3-text':
        'A selected element can be deleted, duplicated, mirrored and moved forwards and backwards via the tool panel on the left-hand side of the canvas.',
      'step-4-header': 'Upload and use your own photos',
      'step-4-text':
        'Create folders and upload your own photos from your computer into the editor by dragging or selecting them from a folder on your computer. You can also upload PDF files and use them as templates.',
      'step-5-header': 'Change your background and fill it with colors',
      'step-5-text':
        'Choose a background from the side menu or fill the canvas with a solid colour. To replace it again, just choose another background from the menu.',
      'step-of': 'of',
    },
  },
  'en.sidepanel': {
    buttons: {
      'add-folder': 'Add folder',
      'add-text-main': 'Add main text',
      'add-text-subsection': 'Add subsection',
      'add-text-subtitle': 'Add subtitle',
      'add-text-title': 'Add title',
      back: 'Back',
      'crop-apply': 'Apply',
      'crop-cancel': 'Cancel',
    },
    controls: {
      'image-dropzone-click': '...or click to select files',
      'image-dropzone-drop': 'Drag and drop to upload images to this folder',
      'image-uploading': 'Uploading image',
      'image-uploading-of': 'of',
      'image-uploading-uploaded': 'pictures uploaded',
      'imagedirectory-delete': 'Delete folder',
      'imagedirectory-open': 'Open folder',
      'imagedirectory-rename': 'Rename folder',
      'imagedirectory-save': 'Save',
      'pdf-dropzone-click': '...or click to select files',
      'pdf-dropzone-drop': 'Drag and drop to upload documents to this folder',
      'pdf-uploading': 'Uploading document',
    },
    items: {
      background: 'Background',
      border: 'Border',
      color: 'Color',
      crop: 'Crop',
      elements: 'Elements',
      filters: 'Filters',
      images: 'Uploads',
      overview: 'Overview',
      properties: 'Properties',
      shadow: 'Shadow',
      spine: 'Spine',
      templates: 'Templates',
      text: 'Text',
    },
    labels: {
      background: 'Background',
      blur: 'Blur',
      'border-width': 'Width',
      charSpacing: 'Letter spacing',
      columns: 'Columns',
      'default-colours': 'Default colours',
      filters: {
        blur: 'Blur',
        brightness: 'Brightness',
        brownie: 'Brownie',
        contrast: 'Contrast',
        grayscale: 'Grayscale',
        hue: 'Hue',
        kodachrome: 'Kodachrome',
        none: 'None',
        polaroid: 'Polaroid',
        saturation: 'Saturation',
        sepia: 'Sepia',
        technicolour: 'Technicolour',
        vintage: 'Vintage',
      },
      folders: 'Folders',
      fontfamily: 'Font',
      fontsize: 'Size',
      'greyscale-colours': 'Greyscale colours',
      lineHeight: 'Line height',
      'offset-x': 'Horizontal distance',
      'offset-y': 'Vertical distance',
      remove: 'Remove',
      rotation: 'Rotation',
      text: 'Text',
      textalign: 'Align',
      textstyle: 'Text style',
      transparency: 'Transparency',
    },
    misc: {
      backcover: 'Backcover',
      'backcover-short': 'Backcover',
      cover: 'Cover',
      'cover-short': 'Cover',
      'empty-directory': 'Your folder is empty',
      'info-imagedirectories': 'Open a folder to upload images, or create a new folder',
      'info-placeholders':
        'Easily frame your memories: Drag and drop photos into placeholders and customize the perfect fit!',
      new: 'New',
      'new-imagefolder': 'New folder',
      pagecount: 'pages',
    },
    tabs: {
      clipart: 'Clipart',
      documents: 'PDF files',
      folders: 'My folders',
      magazines: 'My creations',
      masks: 'Masks',
      overview: 'Document overview',
      'overview-pages': 'Page view',
      'overview-spreads': 'Spread view',
      shapes: 'Shapes',
      spine: 'Spine',
      templates: 'Jilster templates',
    },
  },
  'en.subheader': {
    test: 'test',
    buttons: { 'exit-spine-editor': 'Exit spine editor' },
    help: { tooltip: 'Tutorials', tour: 'Tour', tutorial: 'Tutorial', youtube: 'YouTube' },
    items: {
      color: 'Colour',
      crop: 'Crop',
      filters: 'Filters',
      properties: 'Properties',
      shadow: 'Shadow',
      stroke: 'Border',
      text: 'Text',
      transform: 'Transform',
      transparency: 'Transparency',
    },
    labels: { h: 'H', w: 'W', x: 'X', y: 'Y' },
  },
}
